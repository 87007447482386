import { useState, useEffect, useMemo } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Close } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";

import clsx from "clsx";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import getServiceNames from "../../../../api/get-service-names";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import Form from "../../../../components/Form";
import InlineMessage from "../../../../components/InlineMessage";
import optsModuleConfig from "../../../../config/optsModuleConfig";
import useNotifier from "../../../../hooks/useNotifier";
import handleError from "../../../../utilities/handleError";
import isEmpty from "../../../../utilities/isEmpty";
import useImmer from "../../../../utilities/useImmer";

import getFields from "../../NewOptsMappingContainer/getFields";
import NewServiceName from "../NewServiceName";
import SelectedService from "../SelectedService";

import useStyles from "./styles";

const NewOptIdMapping = ({
  optMappingInfo,
  setOptMappingInfo,
  serviceName,
  setServiceName,
  cdpMarketingProgramOptions,
  cdpMarketingProgramsLoading,
  getcdpMarketingProgramOptions,
  openModal,
  handleClose,
  handleSubmit,
  title,
  editFlow,
  existingOptMappings,
  optMappings,
  showConsentId,
  consentIds,
}) => {
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isCancelClicked, setIsCancelClicked] = useState(false);

  const [serviceNameOptions, setServiceNameOptions] = useState([]);
  const [newServiceName, setNewServiceName] = useImmer({
    marketingProgramName: "",
    serviceName: "",
    serviceNameDescription: "",
  });

  const [newServiceNameModal, setNewServiceNameModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [serviceNamesLoading, setServiceNamesLoading] = useState(false);

  const [serviceNameFocused, setServiceNameFocused] = useImmer({
    marketingProgramName: false,
    serviceName: false,
    serviceNameDescription: false,
  });

  const { t } = useTranslation();

  const { addNotification } = useNotifier();

  const getServiceNamesFromApi = async (marketingProgramNumber) => {
    try {
      setServiceNamesLoading(true);
      const rsp1 = await getServiceNames(marketingProgramNumber, serviceName);
      setServiceNameOptions(rsp1);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setServiceNameOptions([]);
        },
        addNotification,
      });
    } finally {
      setServiceNamesLoading(false);
    }
  };

  const onCloseDeleteModal = () => {
    setNewServiceName((draft) => {
      draft.marketingProgramName = "";
      draft.serviceName = "";
      draft.serviceNameDescription = "";
    });
    setServiceName({});
    setServiceNameFocused((draft) => {
      draft.marketingProgramName = false;
      draft.serviceName = false;
      draft.serviceNameDescription = false;
    });
    setOptMappingInfo((draft) => {
      draft.serviceName = "";
    });
    setOpenDeleteModal(false);
  };

  const [focused, setFocused] = useImmer({});

  const resetState = () => {
    setServiceName({});
    setOptMappingInfo((draft) => {
      draft.channel = "";
      draft.contactPointCategoryCode = "";
      draft.serviceName = "";
      draft.primaryIndicator = "";
      draft.subscriptionOptNumber = "";
      draft.cdpMarketingProgram = "";
    });
    setServiceNameFocused((draft) => {
      draft.marketingProgramName = false;
      draft.serviceName = false;
      draft.serviceNameDescription = false;
    });
    setNewServiceName((draft) => {
      draft.marketingProgramName = "";
      draft.serviceName = "";
      draft.serviceNameDescription = "";
    });
    setFocused((draft) => {
      Object.keys(draft).forEach((key) => {
        draft[key] = false;
      });
    });
    setIsSubmitClicked(false);
  };

  const errors = useMemo(() => {
    const errs = {};
    if (isEmpty(optMappingInfo.cdpMarketingProgram)) {
      if (isSubmitClicked) {
        setFocused((draft) => {
          draft.cdpMarketingProgram = true;
        });
      }
      errs.cdpMarketingProgramError = t("errors.field_required");
    }
    if (isEmpty(optMappingInfo.channel)) {
      if (isSubmitClicked) {
        setFocused((draft) => {
          draft.channel = true;
        });
      }
      errs.channelError = t("errors.field_required");
    }
    if (isEmpty(optMappingInfo.contactPointCategoryCode)) {
      if (isSubmitClicked) {
        setFocused((draft) => {
          draft.contactPointCategoryCode = true;
        });
      }
      errs.contactPointCategoryCodeError = t("errors.field_required");
    }
    if (isEmpty(optMappingInfo.primaryIndicator)) {
      if (isSubmitClicked) {
        setFocused((draft) => {
          draft.primaryIndicator = true;
        });
      }
      errs.primaryIndicatorError = t("errors.field_required");
    }
    if (isEmpty(optMappingInfo.subscriptionOptNumber)) {
      if (isSubmitClicked) {
        setFocused((draft) => {
          draft.subscriptionOptNumber = true;
        });
      }
      errs.subscriptionOptNumberError = t("errors.field_required");
    }
    if (optMappingInfo.subscriptionOptNumber > 1000) {
      if (isSubmitClicked) {
        setFocused((draft) => {
          draft.subscriptionOptNumber = true;
        });
      }
      errs.subscriptionOptNumberError = t("common.errors.less_than_value", {
        field: "Subscription Opt Number",
        max: 1000,
      });
    }
    if (serviceName?.isNewService) {
      if (
        isEmpty(serviceName?.title) ||
        isEmpty(serviceName?.serviceName) ||
        isEmpty(serviceName?.marketingProgramName)
      ) {
        if (isSubmitClicked) {
          setFocused((draft) => {
            draft.serviceName = true;
          });
        }
        errs.serviceNameError = t("errors.field_required");
      }
    } else if (isEmpty(optMappingInfo.serviceName)) {
      if (isSubmitClicked) {
        setFocused((draft) => {
          draft.serviceName = true;
        });
      }
      errs.serviceNameError = t("errors.field_required");
    }
    if (existingOptMappings.length > 0) {
      if (isSubmitClicked) {
        setFocused((draft) => {
          draft.subscriptionOptNumber = true;
        });
      }
      if (
        existingOptMappings.find(
          (mapping) =>
            mapping.cdpMarketingProgram ===
              optMappingInfo.cdpMarketingProgram.title &&
            mapping.serviceName === optMappingInfo.serviceName &&
            mapping.channel ===
              optMappingInfo.channel.contact_point_type_name &&
            mapping.contactPointCategoryCode ===
              optMappingInfo.contactPointCategoryCode
                .contact_point_category_name
        )
      ) {
        errs.subscriptionOptNumberError = t(
          "new_opt_id.errors.existing_mapping"
        );
      } else if (
        existingOptMappings.find(
          (mapping) =>
            mapping.cdpMarketingProgram ===
              optMappingInfo.cdpMarketingProgram.title &&
            mapping.serviceName === optMappingInfo.serviceName &&
            mapping.channel ===
              optMappingInfo.channel.contact_point_type_name &&
            Number(mapping.subscriptionOptNumber) ===
              Number(optMappingInfo.subscriptionOptNumber)
        )
      ) {
        errs.subscriptionOptNumberError = t(
          "new_opt_id.errors.duplicate_subscription_opt_number"
        );
      }
    }
    if (!editFlow && optMappings.length > 0) {
      if (isSubmitClicked) {
        setFocused((draft) => {
          draft.subscriptionOptNumber = true;
        });
      }
      if (
        optMappings.find(
          (mapping) =>
            mapping.cdpMarketingProgram.title ===
              optMappingInfo.cdpMarketingProgram.title &&
            mapping.serviceName === optMappingInfo.serviceName &&
            mapping.channel.contact_point_type_name ===
              optMappingInfo.channel.contact_point_type_name &&
            mapping.contactPointCategoryCode.contact_point_category_name ===
              optMappingInfo.contactPointCategoryCode
                .contact_point_category_name
        )
      ) {
        errs.subscriptionOptNumberError = t(
          "new_opt_id.errors.existing_mapping"
        );
      } else if (
        optMappings.find(
          (mapping) =>
            mapping.cdpMarketingProgram.title ===
              optMappingInfo.cdpMarketingProgram.title &&
            mapping.serviceName === optMappingInfo.serviceName &&
            mapping.channel.contact_point_type_name ===
              optMappingInfo.channel.contact_point_type_name &&
            Number(mapping.subscriptionOptNumber) ===
              Number(optMappingInfo.subscriptionOptNumber)
        )
      ) {
        errs.subscriptionOptNumberError = t(
          "new_opt_id.errors.duplicate_subscription_opt_number"
        );
      }
    }
    return errs;
  }, [optMappingInfo, serviceName, isSubmitClicked]);

  const classes = useStyles();

  const { contactPointTypeOptions, contactPointCategoryOptions } =
    optsModuleConfig;

  const newServiceNameButton = (
    <div
      className={clsx(
        classes.flexContainer,
        classes.addServiceNameBtn,
        classes.noOptionsContainer
      )}
      key="new-service-name-btn"
    >
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setNewServiceName((draft) => {
            draft.marketingProgramName =
              optMappingInfo.cdpMarketingProgram.description;
            draft.serviceName = optMappingInfo.cdpMarketingProgram.description;
          });
          setNewServiceNameModal(true);
        }}
        data-testid="new-service-name"
      >
        {t("opts_mapping.new_service_name")}
      </Button>
    </div>
  );

  useEffect(async () => {
    if (
      optMappingInfo.cdpMarketingProgram &&
      optMappingInfo.cdpMarketingProgram.marketingProgramNumber
    ) {
      await getServiceNamesFromApi(
        optMappingInfo.cdpMarketingProgram.marketingProgramNumber
      );
    }
  }, [optMappingInfo.cdpMarketingProgram]);

  let optMappingFields = getFields(optMappingInfo);
  optMappingFields = optMappingFields
    .map((field) => {
      if (field.label === t("common.labels.marketing_program")) {
        return {
          ...field,
          type: "custom",
          label: t("common.labels.marketing_program"),
          required: true,
          element: Autocomplete,
          props: {
            id: "combo-box-demo",
            helperText: focused.cdpMarketingProgram && (
              <InlineMessage
                message={`${errors.cdpMarketingProgramError}this`}
              />
            ),
            onBlur: () =>
              setFocused((draft) => {
                draft.cdpMarketingProgram = true;
              }),
            onFocus: () =>
              setFocused((draft) => {
                draft.cdpMarketingProgram = false;
              }),
            options: cdpMarketingProgramOptions.map((option) => ({
              ...option,
              title: `${option.marketingProgramNumber} - ${option.description}`,
            })),
            loading: cdpMarketingProgramsLoading,
            getOptionLabel: (option) => option.title || "",
            onChange: async (event, value) => {
              setOptMappingInfo((draft) => {
                draft.cdpMarketingProgram = value;
              });
              if (value) {
                setServiceName({});
                setOptMappingInfo((draft) => {
                  draft.serviceName = "";
                });
              }
            },
            value: optMappingInfo.cdpMarketingProgram,
            renderInput: (params) => (
              <>
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t(
                    "new_opt_id.placeholders.ciam_marketing_program"
                  )}
                  onChange={(event) => {
                    getcdpMarketingProgramOptions(event.target.value);
                  }}
                />
                {errors.cdpMarketingProgramError &&
                  focused.cdpMarketingProgram && (
                    <InlineMessage message={errors.cdpMarketingProgramError} />
                  )}
              </>
            ),
          },
        };
      }
      if (field.label === t("common.labels.channel")) {
        return {
          ...field,
          required: true,
          props: {
            helperText: focused.channel && (
              <InlineMessage message={errors.channelError} />
            ),
            onBlur: () =>
              setFocused((draft) => {
                draft.channel = true;
              }),
            onFocus: () =>
              setFocused((draft) => {
                draft.channel = false;
              }),
            label:
              optMappingInfo.channel === ""
                ? t("opts_mapping.placeholders.channel")
                : "",
            onChange: (event) => {
              const contactPointCategoryOption =
                contactPointCategoryOptions.find(
                  (option) =>
                    option.contact_point_category_name ===
                    event.target.value.default_contact_point_category_name
                );
              setOptMappingInfo((draft) => {
                draft.channel = event.target.value;
                draft.contactPointCategoryCode = contactPointCategoryOption;
              });
            },
            select: true,
            SelectProps: {
              displayEmpty: true,
              renderValue: () => (
                <span>{optMappingInfo.channel.contact_point_type_name}</span>
              ),
            },
            variant: "outlined",
            inputProps: {
              "data-testid": "channel",
            },
            value: optMappingInfo.channel.contact_point_type_name,
            values: contactPointTypeOptions.map((channel) => ({
              label: channel.contact_point_type_name,
              value: channel,
            })),
          },
        };
      }
      if (field.label === t("opts_mapping.contact_point_category_code")) {
        return {
          ...field,
          required: true,
          props: {
            helperText: focused.contactPointCategoryCode && (
              <InlineMessage message={errors.contactPointCategoryCodeError} />
            ),
            onBlur: () =>
              setFocused((draft) => {
                draft.contactPointCategoryCode = true;
              }),
            onFocus: () =>
              setFocused((draft) => {
                draft.contactPointCategoryCode = false;
              }),
            label:
              optMappingInfo.contactPointCategoryCode === ""
                ? t("opts_mapping.placeholders.contact_point_category_code")
                : "",
            onChange: (event) =>
              setOptMappingInfo((draft) => {
                draft.contactPointCategoryCode = event.target.value;
              }),
            select: true,
            SelectProps: {
              displayEmpty: true,
              renderValue: () => (
                <span>
                  {
                    optMappingInfo.contactPointCategoryCode
                      .contact_point_category_name
                  }
                </span>
              ),
            },
            variant: "outlined",
            value:
              optMappingInfo.contactPointCategoryCode
                .contact_point_category_name,
            values: contactPointCategoryOptions
              .filter(
                (category) =>
                  category.contact_point_type_code ===
                  optMappingInfo.channel.contact_point_type_code
              )
              .map((category) => ({
                label: category.contact_point_category_name,
                value: category,
              })),
            inputProps: {
              "data-testid": "contact-point-category-code",
            },
          },
        };
      }
      if (field.label === t("common.labels.service_name")) {
        if (
          serviceName &&
          Object.keys(serviceName).length > 0 &&
          serviceName.isNewService &&
          optMappingInfo.cdpMarketingProgram
        ) {
          return {
            type: "custom",
            element: SelectedService,
            required: true,
            props: {
              helperText: focused.serviceName && (
                <InlineMessage message={errors.serviceNameError} />
              ),
              onBlur: () =>
                setFocused((draft) => {
                  draft.serviceName = true;
                }),
              onFocus: () =>
                setFocused((draft) => {
                  draft.serviceName = false;
                }),
              isNewService: serviceName.isNewService,
              serviceName: serviceName.serviceName,
              onClick: () => {
                setNewServiceName((draft) => {
                  draft.marketingProgramName = serviceName.marketingProgramName;
                  draft.serviceName = serviceName.serviceName;
                  draft.serviceNameDescription =
                    serviceName.serviceNameDescription;
                });
                setNewServiceNameModal(true);
              },
              onDelete: () => {
                setOpenDeleteModal(true);
              },
            },
          };
        }
        let label = "";

        if (serviceNamesLoading && !optMappingInfo.serviceName) {
          label = t("opts_mapping.placeholders.service_loading");
        } else if (!optMappingInfo.serviceName) {
          label = t("opts_mapping.placeholders.service_name");
        }
        return {
          ...field,
          required: true,
          props: {
            helperText: focused.serviceName && (
              <InlineMessage message={errors.serviceNameError} />
            ),
            onBlur: () =>
              setFocused((draft) => {
                draft.serviceName = true;
              }),
            onFocus: () =>
              setFocused((draft) => {
                draft.serviceName = false;
              }),
            label,
            onChange: (event) => {
              setOptMappingInfo((draft) => {
                draft.serviceName = event.target.value;
              });
            },
            value: optMappingInfo.serviceName,
            values: [
              ...serviceNameOptions.map((option) => ({
                label: option.serviceName,
                value: option.serviceName,
              })),
              newServiceNameButton,
            ],
            inputProps: {
              "data-testid": "service-name",
            },
            select: true,
            variant: "outlined",
            disabled:
              !optMappingInfo.cdpMarketingProgram ||
              cdpMarketingProgramsLoading ||
              serviceNamesLoading,
          },
        };
      }
      if (showConsentId && field.label === t("common.labels.consent_id")) {
        return {
          ...field,
          required: true,
          props: {
            helperText: focused.consentId && (
              <InlineMessage message={errors.consentIdError} />
            ),
            onBlur: () =>
              setFocused((draft) => {
                draft.consentId = true;
              }),
            onFocus: () =>
              setFocused((draft) => {
                draft.consentId = false;
              }),
            label:
              optMappingInfo.consentId === ""
                ? t("opts_mapping.placeholders.consent_id")
                : "",
            onChange: (event) =>
              setOptMappingInfo((draft) => {
                draft.consentId = event.target.value;
              }),
            select: true,
            variant: "outlined",
            value: optMappingInfo.consentId,
            values: consentIds?.map((id) => ({
              label: id,
              value: id,
            })),
            inputProps: {
              "data-testid": "consent-id",
            },
          },
          element: TextField,
        };
      }

      if (field.label === t("opts_mapping.primary_indicator")) {
        return {
          ...field,
          required: true,
          props: {
            helperText: focused.primaryIndicator && (
              <InlineMessage message={errors.primaryIndicatorError} />
            ),
            onBlur: () =>
              setFocused((draft) => {
                draft.primaryIndicator = true;
              }),
            onFocus: () =>
              setFocused((draft) => {
                draft.primaryIndicator = false;
              }),
            label:
              optMappingInfo.primaryIndicator === ""
                ? t("opts_mapping.placeholders.primary_indicator")
                : "",
            onChange: (event) =>
              setOptMappingInfo((draft) => {
                draft.primaryIndicator = event.target.value;
              }),
            select: true,
            variant: "outlined",
            value: optMappingInfo.primaryIndicator,
            values:
              optsModuleConfig.createOptConstants.primary_indicator_options.map(
                (option) => ({
                  label: option,
                  value: option,
                })
              ),
            inputProps: {
              "data-testid": "primary-indicator",
            },
            "data-testid": "kind-of-data-collected",
          },
        };
      }
      if (field.label === t("opts_mapping.subscription_opt_number")) {
        return {
          ...field,
          required: true,
          props: {
            helperText: focused.subscriptionOptNumber && (
              <InlineMessage message={errors.subscriptionOptNumberError} />
            ),
            onBlur: () =>
              setFocused((draft) => {
                draft.subscriptionOptNumber = true;
              }),
            onFocus: () =>
              setFocused((draft) => {
                draft.subscriptionOptNumber = false;
              }),
            placeholder: t("opts_mapping.placeholders.subscription_opt_number"),
            onChange: (event) =>
              setOptMappingInfo((draft) => {
                draft.subscriptionOptNumber = event.target.value;
              }),
            value: optMappingInfo.subscriptionOptNumber,
            variant: "outlined",
            inputProps: {
              "data-testid": "subscription-opt-number",
            },
            type: "number",
          },
        };
      }
      return {};
    })
    .filter((field) => !isEmpty(field));

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => {
          handleClose();
          resetState();
        }}
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h4">{title}</Typography>
          <div
            className={clsx(classes.marginLeftAuto, classes.closeBtn)}
            onClick={() => {
              setIsCancelClicked(true);
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        <div className={classes.horizontalBar} />
        <DialogContent>
          <Form
            fields={optMappingFields}
            fieldClassName={clsx(classes.fieldContainer, classes.flexContainer)}
          />
          <div className={classes.horizontalBar} />
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsSubmitClicked(true);
                if (Object.values(errors).length > 0) {
                  return;
                }
                const info = JSON.parse(JSON.stringify(optMappingInfo));
                if (serviceName.isNewService) {
                  info.serviceName = serviceName;
                }
                handleSubmit(info, editFlow ? optMappingInfo : null);
                handleClose();
                resetState();
              }}
              color="primary"
              variant="contained"
            >
              {t("common.submit")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => setIsCancelClicked(false)}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                resetState();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {newServiceNameModal && (
        <NewServiceName
          isOpen={newServiceNameModal}
          newServiceName={newServiceName}
          setNewServiceName={setNewServiceName}
          setServiceName={setServiceName}
          marketingProgramNumber={
            optMappingInfo.cdpMarketingProgram.marketingProgramNumber
          }
          focused={serviceNameFocused}
          setFocused={setServiceNameFocused}
          marketingProgram={optMappingInfo.cdpMarketingProgram}
          setNewServiceNameModal={setNewServiceNameModal}
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message={t("opts_mapping.remove_service_name_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          onCloseDeleteModal();
        }}
        type="error"
      />
    </>
  );
};

NewOptIdMapping.defaultProps = {
  existingOptMappings: [],
  optMappings: [],
};

NewOptIdMapping.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  cdpMarketingProgramOptions: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    })
  ).isRequired,
  getcdpMarketingProgramOptions: PropTypes.func.isRequired,
  cdpMarketingProgramsLoading: PropTypes.bool.isRequired,
  setOptMappingInfo: PropTypes.func.isRequired,
  showConsentId: PropTypes.bool.isRequired,
  consentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  optMappingInfo: PropTypes.shape({
    channel: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      contact_point_type_name: PropTypes.string,
      contact_point_type_code: PropTypes.string,
    }).isRequired,
    contactPointCategoryCode: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      contact_point_category_name: PropTypes.string,
    }).isRequired,
    serviceName: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    }).isRequired,
    primaryIndicator: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    }).isRequired,
    subscriptionOptNumber: PropTypes.string.isRequired,
    consentId: PropTypes.string.isRequired,
    cdpMarketingProgram: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      marketingProgramNumber: PropTypes.number,
      countryCodes: PropTypes.string,
      brandName: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
  }).isRequired,
  serviceName: PropTypes.shape({
    title: PropTypes.string.isRequired,
    isNewService: PropTypes.bool.isRequired,
    serviceName: PropTypes.string.isRequired,
    marketingProgramName: PropTypes.string.isRequired,
    serviceNameDescription: PropTypes.string.isRequired,
  }).isRequired,
  setServiceName: PropTypes.func.isRequired,
  editFlow: PropTypes.bool.isRequired,
  existingOptMappings: PropTypes.shape,
  optMappings: PropTypes.shape,
};

export default NewOptIdMapping;

import updateQuillContent from "./updateQuillContext";

const createBackendPayload = (
  marketingProgram,
  optMappings,
  updatedConsentTexts,
  consentTemplates,
  consentMappingInfo,
  templateVariables
) => {
  const consentTexts = [];
  Object.keys(updatedConsentTexts).forEach((key) => {
    const template = consentTemplates.find(
      (temp) => temp.consentTemplateKey === key
    );
    const { consentId } = updatedConsentTexts[key];
    if (template) {
      consentTexts.push({
        consentId,
        consentTemplateId: template.consentTemplateId,
        country: {
          countryCode: template.countryCode,
          description: template.country,
        },
        language: {
          languageCode: template.languageCode,
          language: template.language,
        },
        consentText: updateQuillContent(
          updatedConsentTexts[template.consentTemplateKey].consentText,
          templateVariables,
          true
        ),
        noticeText: updateQuillContent(
          updatedConsentTexts[template.consentTemplateKey].noticeText,
          templateVariables,
          true
        ),
      });
    }
  });

  const consentIds = [];
  consentMappingInfo?.consentIds?.forEach((item) => {
    if (item?.consentDetails) {
      consentIds.push({
        consentId: item.consentDetails.consentId,
        consentTemplateType: item.consentTemplateType,
        consentVersionNumber: Number(item.consentDetails.latestVersion),
        isNew: false,
        isUpdated: (() => {
          return (
            item?.consentVersions?.length !== consentTexts.length ||
            item?.consentVersions?.find((consentVersion) => {
              const updatedVersion = consentTexts.find(
                (c) =>
                  c.language.languageCode ===
                    consentVersion.language.split(" - ")?.[0] &&
                  c.country.countryCode ===
                    consentVersion.country.split(" - ")?.[0]
              );
              return (
                updatedVersion.noticeText !== consentVersion.noticeText ||
                updatedVersion.consentText !== consentVersion.consentText
              );
            }) !== undefined
          );
        })(),
      });
    } else {
      consentIds.push({
        consentId: item?.consentId,
        consentVersionNumber: 1,
        consentTemplateType: item.consentTemplateType,
        isNew: true,
      });
    }
  });

  const payload = {
    marketingProgram: {
      marketingProgramNumber:
        marketingProgram?.marketingProgramNumber ||
        consentMappingInfo?.consentDetails?.marketingProgram.split(" - ")[0],
      description:
        marketingProgram?.description ||
        consentMappingInfo?.consentDetails?.marketingProgram.split(" - ")[1],
    },
    marketingProgramOpts: optMappings.map((mapping) => {
      return {
        contactPointType: {
          contactPointTypeCode: mapping.channel.contact_point_type_code,
          contactPointTypeName: mapping.channel.contact_point_type_name,
        },
        contactPointCategory: {
          contactPointCategoryCode:
            mapping.contactPointCategoryCode.contact_point_category_code,
          description: mapping.contactPointCategoryCode.description,
        },
        service: {
          serviceName: mapping.serviceName,
        },
        primaryIndicator: mapping.primaryIndicator,
        subscriptionOptNumber: mapping.subscriptionOptNumber,
        consentId: mapping.consentId,
        marketingProgram: {
          marketingProgramNumber:
            mapping.cdpMarketingProgram.marketingProgramNumber,
          description: mapping.cdpMarketingProgram.description,
        },
      };
    }),
    consent: {
      consentIds,
      consentTexts,
    },
  };

  return payload;
};

export default createBackendPayload;

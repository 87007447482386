import i18n from "../../../i18n/init";

const getFields = (optsInfo) => {
  const fields = [
    {
      label: i18n.t("common.labels.consent_id"),
      type: "dropdown",
      flex: 2,
      props: {
        value: optsInfo.consentId,
      },
    },
    {
      label: i18n.t("common.labels.marketing_program"),
      type: "dropdown",
      props: {
        value: optsInfo.marketingProgram,
      },
    },
    {
      label: i18n.t("common.labels.opt_id"),
      type: "dropdown",
      props: {
        value: optsInfo.optId,
      },
    },
    {
      label: i18n.t("common.labels.opt_text"),
      type: "dropdown",
      props: {
        value: optsInfo.optText,
      },
    },
    {
      label: i18n.t("common.labels.service_name"),
      type: "dropdown",
      flex: 2,
      props: {
        value: optsInfo.serviceName,
      },
    },
    {
      label: i18n.t("common.labels.channel"),
      type: "dropdown",
      props: {
        value: optsInfo.channel.contact_point_type_name,
      },
    },
    {
      label: i18n.t("opts_mapping.contact_point_category_code"),
      type: "dropdown",
      flex: 2,
      props: {
        value: optsInfo.contactPointCategoryCode.contact_point_category_name,
      },
    },
    {
      label: i18n.t("opts_mapping.primary_indicator"),
      type: "dropdown",
      props: {
        value: optsInfo.primaryIndicator,
      },
    },
    {
      label: i18n.t("opts_mapping.subscription_opt_number"),
      type: "text-input",
      flex: 2,
      props: {
        value: optsInfo.subscriptionOptNumber,
      },
    },
  ];
  return fields;
};

export default getFields;
